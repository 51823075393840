import { observer } from 'mobx-react';
import * as React from 'react';

interface Props {
  value: boolean;
  onValueChange: (value: boolean) => void;
  disabled: boolean;
  accessibilityLabel?: string;
  accessibilityLabelledBy?: string;
}

const CheckBox: React.FC<Props> = (props) => {
  return (
    <input
      type="checkbox"
      className="flex flex-shrink-0 m-0 w-5 h-5 cursor-pointer"
      onChange={(e) => props.onValueChange(e.target.checked)}
      checked={props.value}
      aria-label={props.accessibilityLabel}
      aria-labelledby={props.accessibilityLabelledBy}
    />
  );
};

export default observer(CheckBox);
