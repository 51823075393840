import { observer } from 'mobx-react';
import * as React from 'react';
import FormField from '../../../Form/FormField';
import TextField from '../../../Form/TextField';
import { InvitationStore } from '../InvitationStore';

interface Props {
  store: InvitationStore;
  value: string;
}

const EmailField: React.FC<Props> = ({ store, value }) => (
  <FormField label="Email or Username">
    <TextField value={value} disabled={!store.emailInputEnabled} />
  </FormField>
);

export default observer(EmailField);
