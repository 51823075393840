import { observer } from 'mobx-react';
import * as React from 'react';
import { Typography } from '../../components/UI';
import { T, TPlainTextKey } from '../../Translations';

interface Props {
  text: TPlainTextKey;
}

const Label: React.FC<Props> = ({ text }) => (
  <Typography
    className="mb-1.5 uppercase tracking-wider"
    variant="caption"
    component="label"
    fontWeight="medium"
  >
    <T kind={text} />
  </Typography>
);

export default observer(Label);
