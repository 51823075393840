import { warn } from '@execonline-inc/logging';
import { ExperienceResource } from '../../../../Common/Experience/Types';
import { Errored, Loading, PromotableError, Ready } from './Types';

export const loading = (): Loading => ({ kind: 'loading' });

export const ready = (data: ReadonlyArray<ExperienceResource>): Ready => ({
  kind: 'ready',
  data,
});

export const errored = (message: string): Errored => ({ kind: 'errored', message });

export function errorMessage(error: PromotableError): string {
  switch (error.kind) {
    case 'http-error':
      return error.response.response.statusText;
    case 'decoder-error':
      warn('Decoder error', error.error, error.response);
      return 'Decoder error';
    case 'network-error':
      return 'Network error';
    case 'missing-link-error':
      return 'The link for getting `experience` was missing';
  }
}
