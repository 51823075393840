import { equals } from '@execonline-inc/collections';
import { CircleSolidIcon, cn, IconButton } from '@execonline-inc/execonline-ui';
import { when } from '@execonline-inc/maybe-adapter';
import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import { Fragment } from 'react';
import { useTranslationsContext } from '../../../../../../Translations/UseTranslationsContext';
import { NavDotPages } from '../CarouselStore/Types';
import { CarouselPage } from '../Types';

interface Props<T> {
  dots: Maybe<NavDotPages<T>>;
  onDotClick: (page: CarouselPage<T>) => void;
}

function NavigationDots<T>({ dots, onDotClick }: Props<T>) {
  const { translate } = useTranslationsContext();

  function handleDotClick(page: CarouselPage<T>) {
    return () => {
      onDotClick(page);
    };
  }
  const className = 'h-4 w-4 rounded-full';

  return dots
    .map(({ pages, currentPageIndex }) => (
      <div className="mt-6 hidden w-full justify-center gap-3 xl:flex">
        {pages.map((currentPage, index) => (
          <Fragment key={index}>
            {when(!equals(index, currentPageIndex), true).cata({
              Just: () => (
                <IconButton
                  aria-label={translate('Go to page {{position}}', { position: index + 1 })}
                  className={className}
                  onClick={handleDotClick(currentPage)}
                >
                  <CircleSolidIcon className="text-gray-100" size="xs" />
                </IconButton>
              ),
              Nothing: () => (
                <IconButton
                  aria-current="true"
                  aria-label={translate('Current page {{current}}', { current: index + 1 })}
                  className={cn('pointer-events-none cursor-default', className)}
                  tabIndex={-1}
                >
                  <CircleSolidIcon className="text-gray-450" size="xs" />
                </IconButton>
              ),
            })}
          </Fragment>
        ))}
      </div>
    ))
    .getOrElse(() => <div className="h-[30px]"></div>);
}

export default observer(NavigationDots);
