import { IconButton, IconButtonProps, SvgIcon } from '@execonline-inc/execonline-ui';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import { MouseEvent } from 'react';
import { VisibilityNavigationButton } from './Types';

interface Props extends IconButtonProps {
  visibility: VisibilityNavigationButton;
  disabled?: boolean;
  icon: typeof SvgIcon;
  onClick: (event?: MouseEvent<HTMLButtonElement>) => void;
}

function NavigationButton({ icon: Icon, disabled, visibility, onClick, ...others }: Props) {
  return (
    <div className="hidden w-16 flex-shrink-0 justify-center xl:flex">
      <IconButton
        className={clsx('h-full w-[30px] items-center text-font-size-4xl lg:w-10', {
          hidden: visibility === 'hide',
        })}
        disabled={disabled}
        onClick={onClick}
        {...others}
      >
        {<Icon color="black" size="inherit" disabled={disabled} />}
      </IconButton>
    </div>
  );
}

export default observer(NavigationButton);
