import { observer } from 'mobx-react';
import * as React from 'react';
import { TPlainTextKey } from '../../Translations';
import Label from './Label';

interface Props {
  label: TPlainTextKey;
}

const FormField: React.FC<Props> = ({ label, children }) => (
  <div className="flex flex-col sm:w-full">
    <Label text={label} />
    {children}
  </div>
);

export default observer(FormField);
