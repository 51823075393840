import { observer } from 'mobx-react';
import * as React from 'react';
import { handleEnterKeyPress } from '../../../../../Accessibility/KeyboardEvents';
import SetPasswordStore from '../../SetPasswordStore';
import PasswordInputField from '../../../../Form/PasswordInputField';
import { T } from '../../../../../Translations';

interface Props {
  store: SetPasswordStore;
}

const ConfirmPasswordField: React.FC<Props> = ({ store }) => (
  <PasswordInputField
    data-testid="register-password-form_confirm-password-text-field"
    label={<T kind="Confirm Password" />}
    labelProps={{ className: 'uppercase tracking-wider text-xs' }}
    value={store.passwordConfirmation.getOrElseValue('')}
    onKeyDown={handleEnterKeyPress(store.creating)}
    onChange={(e) => store.setPasswordConfirmation(e.target.value)}
  />
);

export default observer(ConfirmPasswordField);
