import { observer } from 'mobx-react';
import { SkeletonPromotableExperienceCarousel } from '../PromotableExperienceCarousel';

function SkeletonPromotableExperiences() {
  return (
    <div className="bg-white shadow-section">
      <div
        data-testid="skeleton-promotables-section"
        className="mx-auto flex flex-col bg-inherit px-0 py-10 pb-6 pt-0 xl:container sm:py-8"
      >
        <div data-testid="skeleton-promotables-section-body" className="px-6 md:px-16">
          <SkeletonPromotableExperienceCarousel />
        </div>
      </div>
    </div>
  );
}

export default observer(SkeletonPromotableExperiences);
