import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { T } from '../../Translations';
import InfoIcon from '../Cutesies/InfoIcon';

interface Props {
  impersonatingBy: Maybe<string>;
}

const ImpersonationMessage: React.FC<Props> = ({ impersonatingBy }) =>
  impersonatingBy
    .map((email) => (
      <div className="w-full bg-teal-50-old border-solid border-b-teal-400-old border-b-2 pl-12 py-2 flex items-center">
        <InfoIcon />
        <div className="ml-4 text-black">
          <T kind="Impersonating by {{emailAddress}}" emailAddress={email} />
        </div>
      </div>
    ))
    .getOrElse(() => <></>);

export default observer(ImpersonationMessage);
