import React from 'react';
import { when } from '@execonline-inc/maybe-adapter';
import ThemeContext from '../../Themes/ThemeContext';
import { ColorPalette } from '../../Themes/ThemeContext/Types';
import { defaultTheme } from '../../Themes';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {}

const TextField = ({ disabled = false, ...others }: Props) => {
  const getDisabledBackgroundColorName = (disabled: boolean): keyof ColorPalette => {
    return when<keyof ColorPalette>(disabled, 'disabled-input-bg-color').getOrElseValue(
      'text-white-color',
    );
  };

  return (
    <ThemeContext.Consumer>
      {(theme) => (
        <input
          style={{
            color: defaultTheme.variables['content-text'],
            borderColor: defaultTheme.variables['input-border'],
            backgroundColor: theme.colors[getDisabledBackgroundColorName(disabled)],
          }}
          className="text-font-size-sm box-border border-solid border-1 rounded w-full h-11 p-1.5"
          disabled={disabled}
          {...others}
        />
      )}
    </ThemeContext.Consumer>
  );
};

export default TextField;
