import { observer } from 'mobx-react';
import * as React from 'react';
import { handleEnterKeyPress } from '../../../../../Accessibility/KeyboardEvents';
import SetPasswordStore from '../../SetPasswordStore';
import PasswordInputField from '../../../../Form/PasswordInputField';
import { T } from '../../../../../Translations';

interface Props {
  store: SetPasswordStore;
}

const PasswordField: React.FC<Props> = ({ store }) => (
  <PasswordInputField
    data-testid="register-password-form_password-text-field"
    label={<T kind="Password" />}
    labelProps={{ className: 'uppercase tracking-wider text-xs' }}
    value={store.password.getOrElseValue('')}
    onKeyDown={handleEnterKeyPress(store.creating)}
    onChange={(e) => store.setPassword(e.target.value)}
  />
);

export default observer(PasswordField);
