import { useMediaQuery } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { ExperienceResource } from '../../../Common/Experience/Types';
import { TileViewProgramExperience } from '../../../Common/ProgramExperience';
import EnrollmentStore from '../../Enrollment/Store';
import { useExperiencesContext } from '../../ExperienceSelection/Experiences/ExperiencesContext';
import PromotableExperienceCarouselTileView from './PromotableExperienceCarouselTileView';

interface Props {
  className?: string;
  invitationUuid: string;
  experienceResource: ExperienceResource;
  enrollmentStore: EnrollmentStore;
}

function PromotableExperienceCarouselTile({
  className,
  invitationUuid,
  experienceResource,
  enrollmentStore,
}: Props) {
  const breakpoint = useMediaQuery();
  const experiencesStore = useExperiencesContext();

  switch (breakpoint) {
    case 'xl':
      return (
        <PromotableExperienceCarouselTileView
          invitationUuid={invitationUuid}
          experienceResource={experienceResource}
          experiencesStore={experiencesStore}
          enrollmentStore={enrollmentStore}
        />
      );
    case 'lg':
    case 'md':
    case 'sm':
    case 'xs':
      return (
        <TileViewProgramExperience
          className={className}
          invitationUuid={invitationUuid}
          experienceResource={experienceResource}
          experiencesStore={experiencesStore}
          enrollmentStore={enrollmentStore}
        />
      );
  }
}

export default observer(PromotableExperienceCarouselTile);
