import { emptyFragment } from '@execonline-inc/execonline-ui';
import { AlreadyTranslated } from '@execonline-inc/translations';
import { fromEmpty } from 'maybeasy';
import { observer } from 'mobx-react';
import { Resource } from '../../../../../Resource/Types';
import { ProductCollection } from '../../../Common/Experience/Types';
import {
  ExperienceSection,
  ExperienceSectionBody,
  ExperienceSectionDescription,
  ExperienceSectionHeader,
} from '../../Common/ExperienceSection';
import ExperienceUpdateFocus from '../../Common/ExperienceUpdateFocus';
import EnrollmentStore from '../../Enrollment/Store';
import { TileCarouselExperiences } from '../../ExperienceSelection/Experiences/TileViewCarouselExperiences';

interface Props {
  data: Resource<ProductCollection>;
  invitationUuid: string;
  enrollmentStore: EnrollmentStore;
}

function PromotableProductCollectionView({ data, invitationUuid, enrollmentStore }: Props) {
  const { payload: productCollection } = data;

  return fromEmpty(productCollection.experiences)
    .map((experienceResources) => (
      <div className="bg-white shadow-section">
        <ExperienceSection>
          <ExperienceSectionHeader>
            <ExperienceUpdateFocus
              productCollection={productCollection}
              scopeType="product-collection"
            />
            {productCollection.description
              .map((description) => (
                <ExperienceSectionDescription>
                  <AlreadyTranslated content={description} />
                </ExperienceSectionDescription>
              ))
              .getOrElse(emptyFragment)}
          </ExperienceSectionHeader>
          <ExperienceSectionBody>
            <TileCarouselExperiences
              invitationUuid={invitationUuid}
              experienceResources={experienceResources}
              perPageCount={productCollection.displayPerPageCount}
              enrollmentStore={enrollmentStore}
            />
          </ExperienceSectionBody>
        </ExperienceSection>
      </div>
    ))
    .getOrElse(emptyFragment);
}

export default observer(PromotableProductCollectionView);
