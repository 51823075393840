import { FetchError, RequestOptions } from '@execonline-inc/fetch.private';
import { log, warn } from '@execonline-inc/logging';
import { useAbortable } from '@execonline-inc/react-hooks.private';
import { assertNever } from '@kofno/piper';
import Decoder from 'jsonous';
import { toJS } from 'mobx';
import { Task } from 'taskarian';
import { findLinkByT, useFetchCore } from '../../../../../../Fetch';
import { Link } from '../../../../../../Resource/Types';
import { ExperienceResource } from '../../../../Common/Experience/Types';
import { errored, errorMessage, loading, ready } from './Functions';
import { PromotableError, PromotableState } from './Types';

function fetchProgramFamily(
  get: (
    href: string,
    decoder: Decoder<ExperienceResource>,
    options?: Omit<RequestOptions, 'body'>,
  ) => Task<FetchError, ExperienceResource>,
  links: ReadonlyArray<Link>,
  decoder: Decoder<ExperienceResource>,
) {
  return Task.succeed<PromotableError, ReadonlyArray<Link>>(links)
    .andThen(findLinkByT({ rel: 'self' }))
    .andThen(({ href }) => get(href, decoder))
    .mapError(errorMessage);
}

function useFetchPromotableProgramFamilies(
  linksArray: ReadonlyArray<ReadonlyArray<Link>>,
  decoder: Decoder<ExperienceResource>,
) {
  const { useAbortableState, useAbortableEffect } = useAbortable();
  const [state, setState] = useAbortableState<PromotableState>(loading);
  const { get } = useFetchCore();

  const error = (e: string) => {
    setState(errored(e));
  };

  const success = (data: ReadonlyArray<ExperienceResource>) => {
    setState(ready(data));
  };

  useAbortableEffect(() => {
    switch (state.kind) {
      case 'loading':
        const tasks = linksArray.map((links) => fetchProgramFamily(get, links, decoder));
        Task.all(tasks).fork(error, success);
        break;
      case 'errored':
        warn('Failed to fetch promotable experience resources.', toJS(state));
        break;
      case 'ready':
        log('All of the promotable experience resources have been loaded.');
        break;
      default:
        assertNever(state);
    }
  }, [state]);

  return state;
}

export default useFetchPromotableProgramFamilies;
