import * as React from 'react';
import { observer } from 'mobx-react';
import { Typography } from '../../../components/UI';
import { T } from '../../../Translations';
import FormField from '../../Form/FormField';
import TextField from '../../Form/TextField';

interface Props {
  value: string;
}

const EmailField: React.FC<Props> = ({ value }) => (
  <FormField label="Email or Username">
    <TextField value={value} disabled />
    <Typography variant="body2" fontWeight="medium">
      <T kind="email@domain.com; username@id" />
    </Typography>
  </FormField>
);

export default observer(EmailField);
