import { observer } from 'mobx-react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { resetPasswordRoute } from '../../ClientRoutes';
import { T } from '../../Translations';
import { LoginStore } from '../Store';
import LoginFormWrapper from './LoginFormWrapper';
import * as style from './style.module.css';
import clsx from 'clsx';

interface Props {
  store: LoginStore;
}

const LockoutNotice: React.FC<Props> = ({ store }) => (
  <LoginFormWrapper store={store}>
    <p className={clsx(style.failedLogin, 'text-neutral-900')}>
      <T
        kind="If this email address is in our database you ..."
        emailAddress={
          <a
            className={clsx(style.mailToLink, 'text-neutral-900 hover:text-neutral-900')}
            href="mailto:support@execonline.com"
          >
            support@execonline.com
          </a>
        }
      />
    </p>
    <br />
    <Link
      className={clsx(style.loginLink, 'text-neutral-900 hover:text-neutral-900')}
      to={resetPasswordRoute}
      data-test-forgot-password-link={true}
    >
      <T kind="I forgot my password" />
    </Link>
  </LoginFormWrapper>
);

export default observer(LockoutNotice);
