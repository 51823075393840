import { ScrollBox, Skeleton } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { SkeletonTileViewProgramExperience } from '../../../Common/ProgramExperience';
import { SkeletonPromotableExperienceCarouselTile } from '../PromotableExperienceCarouselTile';

function SkeletonPromotableExperienceCarousel() {
  return (
    <>
      <div className="hidden xl:block">
        <Skeleton className="w-4/12" size="lg" />
        <SkeletonPromotableExperienceCarouselTile />
        <div className="hidden items-center justify-center gap-3 pt-6 xl:flex">
          {Array.from({ length: 3 }, (_, index) => (
            <Skeleton key={index} className="size-3" variant="circle" />
          ))}
        </div>
      </div>
      <ScrollBox className="-mx-2.5 -mb-2.5 flex pb-2.5 pt-3 xl:hidden" orientation="horizontal">
        <div className="flex flex-shrink-0 gap-x-4 px-2.5">
          {Array.from({ length: 4 }, (_, index) => (
            <SkeletonTileViewProgramExperience key={index} className="w-[19.75rem] flex-shrink-0" />
          ))}
        </div>
      </ScrollBox>
    </>
  );
}

export default observer(SkeletonPromotableExperienceCarousel);
