import { observer } from 'mobx-react';
import * as React from 'react';
import SetPasswordStore from '../../SetPasswordStore';
import ConfirmPasswordField from './ConfirmPasswordField';
import PasswordField from './PasswordField';

interface Props {
  store: SetPasswordStore;
}

const PasswordFormFields: React.FC<Props> = ({ store }) => {
  return (
    <div className="flex flex-col sm:flex-row gap-4 md:gap-6">
      <PasswordField store={store} />
      <ConfirmPasswordField store={store} />
    </div>
  );
};

export default observer(PasswordFormFields);
