import { observer } from 'mobx-react';
import { ExperienceResources } from '../../../Common/Experience/Types';
import { ExperienceSection, ExperienceSectionBody } from '../../Common/ExperienceSection';
import EnrollmentStore from '../../Enrollment/Store';
import { PromotableExperienceCarousel } from '../PromotableExperienceCarousel';

interface Props {
  invitationUuid: string;
  experienceResources: ExperienceResources;
  enrollmentStore: EnrollmentStore;
}
function PromotableExperiencesView({
  invitationUuid,
  experienceResources,
  enrollmentStore,
}: Props) {
  return (
    <div className="bg-white shadow-section">
      <ExperienceSection
        data-testid="learning-collection-promotable-section"
        className="bg-inherit pb-6 pt-0 sm:py-8"
      >
        <ExperienceSectionBody data-testid="learning-collection-promotable-section-body">
          <PromotableExperienceCarousel
            invitationUuid={invitationUuid}
            experienceResources={experienceResources}
            enrollmentStore={enrollmentStore}
          />
        </ExperienceSectionBody>
      </ExperienceSection>
    </div>
  );
}

export default observer(PromotableExperiencesView);
