import { observer } from 'mobx-react';
import { useMemo } from 'react';
import { CarouselStore } from '../../../Common/Experience/Carousel';
import { ExperienceResources } from '../../../Common/Experience/Types';
import { ProgramExperienceCarousel } from '../../../Common/ProgramExperienceCarousel';
import EnrollmentStore from '../../Enrollment/Store';
import { PromotableExperienceCarouselTile } from '../PromotableExperienceCarouselTile';

interface Props {
  invitationUuid: string;
  experienceResources: ExperienceResources;
  enrollmentStore: EnrollmentStore;
}

function PromotableExperienceCarousel({
  invitationUuid,
  experienceResources,
  enrollmentStore,
}: Props) {
  const carouselStore = useMemo(
    () => new CarouselStore(experienceResources, 1),
    [experienceResources],
  );

  return (
    <ProgramExperienceCarousel carouselStore={carouselStore}>
      {({ className, items }) =>
        items.map((experienceResource) => (
          <PromotableExperienceCarouselTile
            key={experienceResource.payload.id}
            className={className}
            experienceResource={experienceResource}
            invitationUuid={invitationUuid}
            enrollmentStore={enrollmentStore}
          />
        ))
      }
    </ProgramExperienceCarousel>
  );
}

export default observer(PromotableExperienceCarousel);
