import { emptyFragment } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import * as React from 'react';
import Footer from '../../../../components/Footer';
import { localPublicUrl } from '../../../MobileImageBackground/Types';
import EnrollmentStore from '../Enrollment/Store';
import { PreviewModeContext } from '../PreviewMode/PreviewModeContext';
import Promotables from '../Promotables';
import { RegisterResource } from '../RegisterResourceStore/Types';
import EnrollableExperience from './EnrollableExperience';
import ExperienceFiltersSelectionView from './ExperienceFiltersSelectionView';
import ExperienceSelectionView from './ExperienceSelectionView';
import ExperiencesStore from './Experiences/ExperiencesStore';
import { ExperienceFiltersProvider } from './Filters/FiltersContext';
import Header from './Header';
import Notification from './Notification';

interface Props {
  invitationUuid: string;
  enrollmentStore: EnrollmentStore;
  experiencesStore: ExperiencesStore;
  registerResource: RegisterResource;
}

export function getBackgroundImage(bgImage?: string): React.CSSProperties {
  return {
    backgroundImage: bgImage ? `url("${localPublicUrl(bgImage)}")` : 'bg-chevrons.svg',
    backgroundPositionY: '-80px',
  };
}

function ExperienceSelection({
  invitationUuid,
  experiencesStore,
  enrollmentStore,
  registerResource,
}: Props) {
  return (
    <div
      style={getBackgroundImage()}
      className="bg-chevrons flex flex-grow flex-col bg-[length:100%] bg-repeat-y"
    >
      <Header />
      <ExperienceFiltersProvider>
        <ExperienceFiltersSelectionView experiencesStore={experiencesStore} />
        <Notification notification={enrollmentStore.notification} />
        <div className="flex flex-1 flex-col gap-y-8">
          <Promotables
            invitationUuid={invitationUuid}
            registerResource={registerResource}
            enrollmentStore={enrollmentStore}
            experiencesStore={experiencesStore}
          />
          <ExperienceSelectionView
            invitationUuid={invitationUuid}
            enrollmentStore={enrollmentStore}
            experiencesStore={experiencesStore}
          />
          <EnrollableExperience
            invitationUuid={invitationUuid}
            notEnrollableAlert={experiencesStore.notEnrollableAlert}
            enrollmentStore={enrollmentStore}
          />
        </div>
      </ExperienceFiltersProvider>
      <PreviewModeContext.Consumer>
        {(previewMode) => {
          switch (previewMode) {
            case 'default':
              return <Footer />;
            case 'preview':
              return emptyFragment();
          }
        }}
      </PreviewModeContext.Consumer>
    </div>
  );
}

export default observer(ExperienceSelection);
