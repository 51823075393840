import { Button, emptyFragment, Modal, useOpenable } from '@execonline-inc/execonline-ui';
import { when } from '@execonline-inc/maybe-adapter';
import { AlreadyTranslated } from '@execonline-inc/translations';
import { observer } from 'mobx-react';
import { Link } from '../../../../../../Resource/Types';
import { LinkableDiscoveryPortalWizardType } from '../../../../../../components/Linkable/Types';
import UpdateFocusModal from '../UpdateFocusModal';

interface Props {
  data: LinkableDiscoveryPortalWizardType;
  links: ReadonlyArray<Link>;
}

function UpdateFocusButtonContent({ data, links }: Props) {
  const { isOpen, onOpen, onClose } = useOpenable();

  return (
    <>
      {when(isOpen, true)
        .map(() => (
          <Modal
            className="max-h-screen sm:max-h-[calc(100vh-25px)]"
            open
            size="6xl"
            onClose={onClose}
          >
            <UpdateFocusModal links={links} onClose={onClose} />
          </Modal>
        ))
        .getOrElse(emptyFragment)}
      <Button className="ml-auto p-0" variant="link" onClick={onOpen}>
        <AlreadyTranslated content={data.label} />
      </Button>
    </>
  );
}

export default observer(UpdateFocusButtonContent);
