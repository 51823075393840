import { observer } from 'mobx-react';
import * as React from 'react';
import { Button } from '../../../../components/UI';
import { T } from '../../../../Translations';
import { RegisterResource } from '../../DiscoveryPortal/RegisterResourceStore/Types';
import TermsAndConditionsFormField from '../../TermsAndConditionsFormField';
import EmailField from '../EmailField';
import { AuthSignUp } from '../Types';
import FormFields from './FormFields';

interface Props {
  registerResource: RegisterResource;
  authForm: AuthSignUp;
}

const Form: React.FC<Props> = ({ registerResource, authForm }) => {
  switch (registerResource.payload.auth.kind) {
    case 'password-auth':
    case 'sso-auth':
      return (
        <div className="flex flex-col w-[600px] max-w-full gap-4 md:gap-6">
          <div className="flex sm:gap-6">
            <div className="basis-full sm:basis-1/2">
              <EmailField value={registerResource.payload.emailAddress} />
            </div>
            <div className="sm:basis-1/2" />
          </div>
          <FormFields authForm={authForm} />
          {registerResource.payload.auth.termsAccepted || (
            <TermsAndConditionsFormField
              value={authForm.store.termsAcceptance}
              onValueChange={authForm.store.setTermsAcceptance}
              disabled={false}
            />
          )}
          <div className="mt-6">
            <Button
              size="xl"
              onClick={authForm.store.creating}
              disabled={authForm.store.formDisabled}
            >
              <T kind="Continue" />
            </Button>
          </div>
        </div>
      );
    case 'locked-access-auth':
      return <></>;
  }
};

export default observer(Form);
