import {
  CloseIcon,
  emptyFragment,
  MagnifyingGlassIcon,
  TextField,
} from '@execonline-inc/execonline-ui';
import { whenZero } from '@execonline-inc/numbers';
import { fromNullable } from 'maybeasy';
import { observer } from 'mobx-react';
import { useRef } from 'react';
import { translation, TranslationsContext } from '../../../../../../Translations';

export interface Props {
  search: string;
  onChange: (value: string) => void;
}

function SearchBoxField({ search, onChange }: Props) {
  const ref = useRef<HTMLInputElement>(null);

  const handleClear = () => {
    fromNullable(ref.current).do((current) => current.focus());
    onChange('');
  };

  const closeIcon = () =>
    whenZero(search.length)
      .map(emptyFragment)
      .getOrElse(() => <CloseIcon size="sm" className="cursor-pointer" onClick={handleClear} />);

  return (
    <TranslationsContext.Consumer>
      {(ts) => (
        <TextField
          ref={ref}
          className="h-full rounded-md !shadow-none !outline-0"
          inputClassName="pl-0 "
          value={search}
          placeholder={translation('Search in this collection')(ts)}
          startContent={<MagnifyingGlassIcon size="sm" />}
          autoFocus
          fullWidth
          endContent={closeIcon()}
          onChange={(e) => onChange(e.target.value)}
        />
      )}
    </TranslationsContext.Consumer>
  );
}

export default observer(SearchBoxField);
