import { emptyFragment } from '@execonline-inc/execonline-ui';
import { warn } from '@execonline-inc/logging';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { useFetch, useFindLinkByR } from '../../../../../../Fetch';
import { Link } from '../../../../../../Resource/Types';
import { segmentResourceDecoder } from '../../../../../../SegmentStore/Decoders';
import SkeletonUpdateFocusModalView from './SkeletonUpdateFocusModalView';
import UpdateFocusModalView from './UpdateFocusModalView';

interface Props {
  links: ReadonlyArray<Link>;
  onClose: () => void;
}

function UpdateFocusModal({ links, onClose }: Props) {
  const link = useFindLinkByR({ rel: 'view', method: 'get' }, links);
  const segment = useFetch(link, segmentResourceDecoder);

  switch (segment.kind) {
    case 'ready':
      return <UpdateFocusModalView segment={segment.data.payload} onClose={onClose} />;
    case 'loading':
      return <SkeletonUpdateFocusModalView />;
    case 'errored':
      warn('Failed to fetch segment resource.', toJS(segment.error));
      return emptyFragment();
  }
}

export default observer(UpdateFocusModal);
