import { Card, CardBody, CardFooter, cn, Skeleton } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';

interface Props {
  className?: string;
}

function SkeletonListViewProgramExperience({ className }: Props) {
  return (
    <Card
      className={cn(
        'box-border flex-grow rounded',
        'bg-white text-gray-800 shadow-card',
        'w-full',
        className,
      )}
    >
      <CardBody className="gap-6 px-6 pb-12 pt-6">
        <div className="flex justify-between">
          <Skeleton className="w-6/12 md:w-4/12" size="md" />
          <Skeleton className="w-2/12" size="md" />
        </div>
        <div className="flex w-full gap-6 md:w-3/12">
          {Array.from({ length: 3 }, (_, index) => (
            <Skeleton key={index} className="w-4/12" />
          ))}
        </div>
        <div className="flex w-full gap-2 md:w-5/12">
          {Array.from({ length: 4 }, (_, index) => (
            <Skeleton key={index} className="w-6/12" variant="rectangle" />
          ))}
        </div>
      </CardBody>
      <CardFooter className="p-6 pt-0">
        <div className="flex w-full justify-between">
          <Skeleton className="w-4/12 md:w-2/12" variant="rectangle" />
          <Skeleton className="w-1/12" variant="rectangle" />
        </div>
      </CardFooter>
    </Card>
  );
}

export default observer(SkeletonListViewProgramExperience);
