import { observer } from 'mobx-react';
import * as React from 'react';
import { InvitationStore } from '../../InvitationStore';
import { LockedAccessState } from '../../InvitationStore/Types';
import FirstNameField from './FirstNameField';
import LastNameField from './LastNameField';

interface Props {
  store: InvitationStore;
  state: LockedAccessState;
}

const ProfileFields: React.FC<Props> = ({ store, state }) => {
  return (
    <div className="flex flex-col gap-4 sm:flex-row md:gap-6">
      <FirstNameField store={store} value={state.firstName} />
      <LastNameField store={store} value={state.lastName} />
    </div>
  );
};

export default observer(ProfileFields);
