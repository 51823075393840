import { fromEmpty, isNothing } from 'maybeasy';
import { when } from '@execonline-inc/maybe-adapter';
import { CarouselPage } from '../Types';

export type VisibilityNavigationButton = 'show' | 'hide';

export function getNavigationButtonStatus<T>(items: ReadonlyArray<CarouselPage<T>>) {
  return isNothing(fromEmpty(items));
}

export function visibilityNavigationButton(
  perPageCount: number,
  totalCount: number,
): VisibilityNavigationButton {
  return when<VisibilityNavigationButton>(totalCount <= perPageCount, 'hide').getOrElseValue(
    'show',
  );
}
