import { observer } from 'mobx-react';
import * as React from 'react';
import { AuthSignUp } from '../Types';
import PasswordFormFields from './PasswordFormFields';

interface Props {
  authForm: AuthSignUp;
}

const FormFields: React.FC<Props> = ({ authForm }) => {
  switch (authForm.kind) {
    case 'password-sign-up':
      return <PasswordFormFields store={authForm.store} />;
    case 'sso-sign-up':
      return <></>;
  }
};

export default observer(FormFields);
