import { useEffect, useState } from 'react';
import { just, Maybe, nothing } from 'maybeasy';
import { whenGt } from '@execonline-inc/numbers';
import { State } from '../../../Experiences/ExperiencesStore/Types';

const searchValue = (state: State): Maybe<string> => {
  switch (state.kind) {
    case 'error':
    case 'loading':
    case 'waiting':
    case 'product-collections':
      return nothing();
    case 'experiences':
    case 'product-collections-and-experiences':
    case 'searching':
      return state.search;
  }
};

function useSearchBox(state: State) {
  const [value, onChange] = useState('');

  useEffect(() => {
    onChange(searchValue(state).getOrElseValue(''));
  }, [searchValue(state)]);

  const validateValue = () => {
    return just(value.length).andThen(whenGt(0));
  };

  return { value, validateValue, onChange } as const;
}

export default useSearchBox;
