import { observer } from 'mobx-react';
import * as React from 'react';
import { T } from '../../../../Translations';
import { Button } from '../../../../components/UI';
import { RegisterResource } from '../../DiscoveryPortal/RegisterResourceStore/Types';
import EmailField from '../EmailField';
import SsoLoginStore from '../SsoLoginStore';

interface Props {
  ssoLoginStore: SsoLoginStore;
  registerResource: RegisterResource;
}

const SsoForm: React.FC<Props> = ({ ssoLoginStore, registerResource }) => (
  <div className="flex flex-col w-[600px] max-w-full gap-4 md:gap-6">
    <div className="flex sm:gap-6">
      <div className="basis-full sm:basis-1/2">
        <EmailField value={registerResource.payload.emailAddress} />
      </div>
      <div className="sm:basis-1/2" />
    </div>
    <div className="mt-6">
      <Button size="xl" onClick={ssoLoginStore.created} disabled={ssoLoginStore.formDisabled}>
        <T kind="Continue with SSO" />
      </Button>
    </div>
  </div>
);

export default observer(SsoForm);
