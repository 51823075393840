import * as style from '../style.module.css';
import * as React from 'react';
import ScheduledToastStore from '../../../ToastsStore/ScheduledToastStore';
import { T, L } from '../../../Translations';
import { observer } from 'mobx-react';
import EventAlertIcon from '../../Cutesies/EventAlertIcon';

interface Props {
  store: ScheduledToastStore;
}

const StartingTemplate: React.FC<Props> = ({ store }) => {
  return (
    <div className={style.content}>
      <div className={style.icon}>
        <EventAlertIcon />
      </div>
      <div className={style.details}>
        <h2 className={style.title}>
          <T kind="Your Live Meeting is starting soon!" />
        </h2>
        <p className={style.description} data-test-toast-description={store.scheduledToast.title}>
          <T
            kind="The {{title}} event is starting at <time/>."
            title={store.scheduledToast.title.text}
            time={<L localizeable={store.scheduledToast.startAt} format="time-of-day" />}
          />
        </p>
      </div>
    </div>
  );
};

export default observer(StartingTemplate);
