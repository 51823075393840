import { observer } from 'mobx-react';
import * as React from 'react';
import { RegisterResource } from '../../../DiscoveryPortal/RegisterResourceStore/Types';
import EmailField from '../../EmailField';
import UsePasswordStore from '../../UsePasswordStore';
import PasswordField from './PasswordField';

interface Props {
  registerResource: RegisterResource;
  usePasswordStore: UsePasswordStore;
}

const FormFields: React.FC<Props> = ({ registerResource, usePasswordStore }) => {
  return (
    <div className="flex flex-col sm:flex-row gap-4 md:gap-6">
      <EmailField value={registerResource.payload.emailAddress} />
      <PasswordField store={usePasswordStore} />
    </div>
  );
};

export default observer(FormFields);
