import { observer } from 'mobx-react';
import * as React from 'react';
import FormField from '../../../../Form/FormField';
import TextField from '../../../../Form/TextField';
import { InvitationStore } from '../../InvitationStore';

interface Props {
  store: InvitationStore;
  value: string;
}

const FirstNameField: React.FC<Props> = ({ store, value }) => (
  <FormField label="First Name">
    <TextField
      value={value}
      disabled={!store.formInputsEnabled}
      onChange={(e) => store.setFirstName(e.target.value)}
    />
  </FormField>
);

export default observer(FirstNameField);
