import { ChevronLeftIcon, ChevronRightIcon } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { PropsWithChildren } from 'react';
import { useTranslationsContext } from '../../../../../../Translations/UseTranslationsContext';
import CarouselStore from '../CarouselStore';
import NavigationButton from './NavigationButton';
import NavigationDots from './NavigationDots';
import { getNavigationButtonStatus, visibilityNavigationButton } from './Types';

interface Props<T> {
  carouselStore: CarouselStore<T>;
}

function CarouselWithButtons<T>({ carouselStore, children }: PropsWithChildren<Props<T>>) {
  const { translate } = useTranslationsContext();

  const visibility = visibilityNavigationButton(
    carouselStore.perPageCount,
    carouselStore.totalCount,
  );

  return (
    <div className="relative overflow-visible xl:-mx-16">
      <div className="mt-8 flex w-full justify-between">
        <NavigationButton
          aria-label={translate('Previous page')}
          icon={ChevronLeftIcon}
          visibility={visibility}
          disabled={getNavigationButtonStatus(carouselStore.precedingPages)}
          onClick={carouselStore.retreat}
        />
        {children}
        <NavigationButton
          aria-label={translate('Next page')}
          icon={ChevronRightIcon}
          visibility={visibility}
          disabled={getNavigationButtonStatus(carouselStore.succeedingPages)}
          onClick={carouselStore.advance}
        />
      </div>
      <NavigationDots dots={carouselStore.navDots} onDotClick={carouselStore.gotoPage} />
    </div>
  );
}

export default observer(CarouselWithButtons);
