import { observer } from 'mobx-react';
import * as React from 'react';
import { handleEnterKeyPress } from '../../../../Accessibility/KeyboardEvents';
import { Button, Typography } from '../../../../components/UI';
import FormField from '../../../Form/FormField';
import TextField from '../../../Form/TextField';
import { T } from '../../../../Translations';
import {
  AuthenticatingEmailDomain,
  PendingEmailConfirmation,
  RequestingEmailAddress,
} from '../InvitationStore/Types';
import { InvitationStore } from '../InvitationStore';

interface Props {
  store: InvitationStore;
  state: RequestingEmailAddress | AuthenticatingEmailDomain | PendingEmailConfirmation;
}

const EmailForm: React.FC<Props> = ({ store, state }) => (
  <div className="flex flex-col w-[600px] max-w-full gap-4 md:gap-6">
    <div className="flex sm:gap-6">
      <div className="basis-full sm:basis-1/2">
        <FormField label="Email or Username">
          <TextField
            value={state.emailAddress}
            disabled={!store.emailInputEnabled}
            onChange={(e) => store.setEmailAddress(e.target.value)}
            onKeyDown={handleEnterKeyPress(store.authenticatingEmailDomain)}
          />
          <Typography variant="body2" fontWeight="medium">
            <T kind="email@domain.com; username@id" />
          </Typography>
        </FormField>
      </div>
      <div className="sm:basis-1/2" />
    </div>
    <div className="mt-6">
      <Button size="xl" onClick={store.authenticatingEmailDomain}>
        <T kind="Get Access" />
      </Button>
    </div>
  </div>
);

export default observer(EmailForm);
